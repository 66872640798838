import {create} from 'zustand';

interface AuthStore {
  token: string | null;
  setToken: (v: string | null) => void;
  id: string | null;
  setId: (id: string | null) => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  token: null,
  id: null,
  setToken: (value) => {
    set({token: value});
  },
  setId: (value) => {
    set({id: value});
  },
}));
