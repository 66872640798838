import {AuthApi} from '@src/api/auth-api';
import {RegisterStorage} from './register-storage';
import router from 'next/router';
import {UserApi} from '@src/api/user-api';
import {AuthStorage} from './auth-storage';
import {useAuthStore} from '@src/stores/auth-store';

class AuthService {
  isClubLogin = false;

  handleLoginRedirect = (res: AuthApi.LoginRes) => {
    if (res?.create_club && res?.is_first_login) {
      RegisterStorage.setConfigurationNeeded();
      router.push('/create-club-question');
      return;
    }
    if (res?.create_club || this.isClubLogin) {
      this.isClubLogin = false;
      router.push('/create-club-question');
      return;
    }
    if (res?.is_first_login) {
      router.push('/user-profile-configuration');
      return;
    }
    if (res?.club_configuration) {
      //@ts-ignore
      async function fetchClubsAndRedirect() {
        const clubs = await UserApi.getCurrentUserClubs();
        if (clubs.length === 0) {
          router.push('/');
        }
        if (
          clubs.length > 0 &&
          clubs[0] &&
          clubs[0].emailVerified &&
          clubs[0].myUserCompany?.roles?.includes('PRESIDENT')
        ) {
          router.push('/club-configuration/' + clubs[0].id);
        } else if (
          clubs.length > 0 &&
          clubs[0] &&
          !clubs[0].emailVerified &&
          clubs[0].myUserCompany?.roles?.includes('PRESIDENT')
        ) {
          router.push(`/club-registration-link/${clubs[0].id}`);
        } else {
          router.push('/');
        }
      }
      fetchClubsAndRedirect();
      return;
    }
    router.push('/');
  };

  handleClubLoginRedirect = () => {
    this.isClubLogin = true;
    router.push('/sign-in');
  };

  emailToConfirm: string | null = null;

  handleEmailConfirmRedirect = (email: string) => {
    this.emailToConfirm = email;
    router.push('/registration-link-sent');
  };
  logout = () => {
    AuthStorage.removeToken();
    AuthStorage.removeGoogleExpiresIn();
    AuthStorage.removeGoogleToken();
    const setToken = useAuthStore.getState().setToken;
    const setId = useAuthStore.getState().setId;
    setToken(null);
    setId(null);
  };
}

export const authService = new AuthService();
