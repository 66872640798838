type AvailableLanguages = {
  label: 'german' | 'english' | 'french' | 'italian' | 'spanish' | 'polish';
  value: 'DE' | 'EN' | 'FR' | 'IT' | 'ES' | 'PL';
}[];

const availableLanguages: AvailableLanguages = [
  {label: 'german', value: 'DE'},
  {
    label: 'english',
    value: 'EN',
  },
  {label: 'french', value: 'FR'},
  {label: 'italian', value: 'IT'},
  {label: 'spanish', value: 'ES'},
  // {label: 'polish', value: 'PL'},
];

export default availableLanguages;
