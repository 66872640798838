import {Place} from '@src/common/inputs/GSearch';

export interface RegistrationBodyStep1 {
  title: string;
  gender?: string;
  salutation: string;
  firstName: string;
  secondName?: string;
  familyName?: string;
  surname: string;
  address: Place;
  userLanguages: string[];
}
export interface RegistrationClubBodyStep1 {
  name: string;
  shortName?: string;
  address?: Place;
  website?: string;
  facebook?: string;
  twitter?: string;
  instagram?: string;
}
export interface RegistrationBodyStep2 {
  phoneNumber: string;
  phoneNumberPrivate?: string;
  phoneNumberBusiness?: string;
  email1: string;
  email2?: string;
  companyName?: string;
}
export interface RegistrationClubBodyStep2 {
  email: string;
  memberplaceEmail: string;
  phone: string;
  memberplacePhone: string;
  currency?: string;
  maxMembers?: string;
  language?: string;
}
export interface RegistrationBodyStep3 {
  birthdate: string;
  password: string;
}
export interface RegistrationBodyClubStep3 {
  chosenCategories: ChosenCategory[];
}
export interface RegistrationBodyClubStep5 {
  foundingDate?: string;
  iban?: string;
  lawForm?: string;
  isVat: boolean;
  percent?: string;
  uid?: string;
}

export interface ChosenCategory {
  category: string;
  subCategory: string;
  subSubCategory: string;
  description?: string;
}

export const RegisterStorage = {
  //GENERAL

  setConfigurationNeeded: () => {
    return localStorage.setItem('userConfiguration', 'true');
  },
  getConfigurationNeeded: () => {
    const item = localStorage.getItem('userConfiguration');
    try {
      if (item !== null) {
        return item as string;
      }
      return null;
    } catch (e) {
      localStorage.removeItem('userConfiguration');
      return null;
    }
  },
  removeConfigurationNeeded: () => {
    return localStorage.removeItem('userConfiguration');
  },

  setRegistrationType: (type: 'user' | 'club') => {
    return localStorage.setItem('registrationType', JSON.stringify(type));
  },

  getType: () => {
    const item = localStorage.getItem('registrationType');
    try {
      if (item !== null) {
        return JSON.parse(item) as 'user' | 'club';
      }
      return null;
    } catch (e) {
      localStorage.removeItem('registerUserStepTwo');
      return null;
    }
  },
  clearRegisterLS: () => {
    localStorage.removeItem('registerUserStepOne');
    localStorage.removeItem('registerUserStepTwo');
    localStorage.removeItem('registerUserStepThree');
    localStorage.removeItem('registerClubStepOne');
    localStorage.removeItem('registerClubStepTwo');
    localStorage.removeItem('registerClubStepThree');
    localStorage.removeItem('registerClubStepFive');
    localStorage.removeItem('registrationType');
  },
  //GENERAL

  // USER REGISTRATION

  setStepOne: (body: RegistrationBodyStep1) => {
    return localStorage.setItem('registerUserStepOne', JSON.stringify(body));
  },
  setStepTwo: (body: RegistrationBodyStep2) => {
    return localStorage.setItem('registerUserStepTwo', JSON.stringify(body));
  },
  setStepThree: (body: RegistrationBodyStep3) => {
    return localStorage.setItem('registerUserStepThree', JSON.stringify(body));
  },

  getStepOne: () => {
    const item = localStorage.getItem('registerUserStepOne');
    try {
      if (item !== null) {
        return JSON.parse(item) as RegistrationBodyStep1;
      }
      return null;
    } catch (e) {
      localStorage.removeItem('registerUserStepOne');
      return null;
    }
  },
  getStepTwo: () => {
    const item = localStorage.getItem('registerUserStepTwo');
    try {
      if (item !== null) {
        return JSON.parse(item) as RegistrationBodyStep2;
      }
      return null;
    } catch (e) {
      localStorage.removeItem('registerUserStepTwo');
      return null;
    }
  },
  getStepThree: () => {
    const item = localStorage.getItem('registerUserStepThree');
    try {
      if (item !== null) {
        return JSON.parse(item) as RegistrationBodyStep3;
      }
      return null;
    } catch (e) {
      localStorage.removeItem('registerUserStepThree');
      return null;
    }
  },
  // USER REGISTRATION
  //CLUB REGISTRATION
  setClubStepOne: (body: RegistrationClubBodyStep1) => {
    return localStorage.setItem('registerClubStepOne', JSON.stringify(body));
  },
  getClubStepOne: () => {
    const item = localStorage.getItem('registerClubStepOne');
    try {
      if (item !== null) {
        return JSON.parse(item) as RegistrationClubBodyStep1;
      }
      return null;
    } catch (e) {
      localStorage.removeItem('registerClubStepOne');
      return null;
    }
  },
  setClubStepTwo: (body: RegistrationClubBodyStep2) => {
    return localStorage.setItem('registerClubStepTwo', JSON.stringify(body));
  },
  getClubStepTwo: () => {
    const item = localStorage.getItem('registerClubStepTwo');
    try {
      if (item !== null) {
        return JSON.parse(item) as RegistrationClubBodyStep2;
      }
      return null;
    } catch (e) {
      localStorage.removeItem('registerClubStepTwo');
      return null;
    }
  },
  setClubStepThree: (body: RegistrationBodyClubStep3) => {
    return localStorage.setItem('registerClubStepThree', JSON.stringify(body));
  },
  getClubStepThree: () => {
    const item = localStorage.getItem('registerClubStepThree');
    try {
      if (item !== null) {
        return JSON.parse(item) as RegistrationBodyClubStep3;
      }
      return null;
    } catch (e) {
      localStorage.removeItem('registerClubStepThree');
      return null;
    }
  },
  setClubStepFive: (body: RegistrationBodyClubStep5) => {
    return localStorage.setItem('registerClubStepFive', JSON.stringify(body));
  },
  getClubStepFive: () => {
    const item = localStorage.getItem('registerClubStepFive');
    try {
      if (item !== null) {
        return JSON.parse(item) as RegistrationBodyClubStep5;
      }
      return null;
    } catch (e) {
      localStorage.removeItem('registerClubStepFive');
      return null;
    }
  },
  //CLUB REGISTRATION
};
