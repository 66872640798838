import React from 'react';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import {lightTheme} from './theme';

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const ThemeProvider = ({children}: ThemeProviderProps) => {
  return (
    <StyledThemeProvider theme={lightTheme}>{children}</StyledThemeProvider>
  );
};
