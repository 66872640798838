import {User} from '@src/models/user';
import {create} from 'zustand';

interface UserStore {
  user: User | null;
  setUser: (v: User | null) => void;
}

export const useUserStore = create<UserStore>((set) => ({
  user: null,
  setUser: (value) => {
    set({user: value});
  },
}));
