export const AuthStorage = {
  setToken: (token: string) => {
    return localStorage.setItem('jwt', token);
  },
  getToken: () => {
    return localStorage.getItem('jwt');
  },
  removeToken: () => {
    return localStorage.removeItem('jwt');
  },

  setId: (id: string) => {
    return localStorage.setItem('id', id);
  },
  getId: () => {
    return localStorage.getItem('id');
  },
  removeId: () => {
    return localStorage.removeItem('id');
  },
  removeGoogleToken: () => {
    return localStorage.removeItem('google_access_token');
  },
  removeGoogleExpiresIn: () => {
    return localStorage.removeItem('google_expires_in');
  },
};
