import {useRouter} from 'next/router';
import {useCallback, useEffect, useState} from 'react';
import {AuthLoading} from '../components/AuthLoading';
import {UserApi} from '@src/api/user-api';
import {useUserStore} from '@src/stores/user-store';
import {useAuthStore} from '@src/stores/auth-store';
import {AuthStorage} from './auth-storage';

interface AuthProviderProps {
  children: JSX.Element;
  role: PageAuthUserRole;
}

export type PageAuthUserRole = 'USER' | 'ADMIN' | 'BOTH' | 'GUEST';

export function AuthProvider({children, role}: AuthProviderProps) {
  const token = useAuthStore((state) => state.token);
  const setToken = useAuthStore((state) => state.setToken);
  const setId = useAuthStore((state) => state.setId);

  const retrieveToken = useCallback((): string | null => {
    if (token) {
      return token;
    }
    const tokenFromStorage = AuthStorage.getToken();
    const idFromStorage = AuthStorage.getId();
    if (tokenFromStorage) {
      setToken(tokenFromStorage);
      setId(idFromStorage);
      return tokenFromStorage;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setToken, token]);

  const [loading, setLoading] = useState(!token);
  const router = useRouter();
  const setUser = useUserStore((state) => state.setUser);

  useEffect(() => {
    async function checkAuth() {
      const token = retrieveToken();
      const isAuth = Boolean(token);
      if (!isAuth && router.query && router.pathname.includes('presence')) {
        const eventId = router.query.presenceId;
        return router.push(`/sign-in?next=presence/${eventId}`);
      }
      if (!isAuth && role !== 'GUEST') {
        return router.push('/sign-in');
      }
      try {
        const user = await UserApi.getCurrentUser();
        setUser(user);
        if (!user && role !== 'GUEST') {
          return router.push('/sign-in');
        }
        if (user.role !== role && role !== 'BOTH') {
          if (user.role === 'ADMIN') {
            router.push('/admin/news');
          } else {
            router.push('/sign-in');
          }
        }
        setLoading(false);
      } catch (error) {
        return router.push('/sign-in');
      }
    }
    checkAuth();
  }, [retrieveToken, role, router, setUser]);

  if (loading) {
    return <AuthLoading />;
  }

  return children;
}
