import {env} from '@src/config';
import { authService } from '@src/features/auth/logic/auth-service';
import {LanguageStorage} from '@src/features/my-profile/logic/language-storage';
import {useAuthStore} from '@src/stores/auth-store';
import axios from 'axios';

export const api = axios.create({
  baseURL: env.NEXT_PUBLIC_BASE_API_URL + '/api/',
});

api.interceptors.request.use((config) => {
  config.headers['Accept-Language'] =
    LanguageStorage.getLanguage()?.language || 'de';
  const token = useAuthStore.getState().token;
  if (token) {
    // @ts-ignore
    config.headers['Authorization'] = 'Bearer ' + token;
  }
  return config;
});

api.interceptors.response.use((config) => {
  if (config.status === 401) {
    authService.logout();
  }
  return config;
});
