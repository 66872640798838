// src/env.mjs
import {createEnv} from '@t3-oss/env-nextjs';
import {z} from 'zod';

export const env = createEnv({
  /*
   * Serverside Environment variables, not available on the client.
   * Will throw if you access these variables on the client.
   */
  server: {},
  /*
   * Environment variables available on the client (and server).
   *
   * 💡 You'll get typeerrors if these are not prefixed with NEXT_PUBLIC_.
   */
  client: {
    NEXT_PUBLIC_GOOGLE_API_KEY: z.string().min(1),
    NEXT_PUBLIC_BASE_API_URL: z.string().min(1),
    NEXT_PUBLIC_WEBSOCKET: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_EVENT_CLIENT: z.string().min(1),
    NEXT_PUBLIC_GOOGLE_EVENT_API: z.string().min(1),
  },
  /*
   * Due to how Next.js bundles environment variables on Edge and Client,
   * we need to manually destructure them to make sure all are included in bundle.
   *
   * 💡 You'll get typeerrors if not all variables from `server` & `client` are included here.
   */
  runtimeEnv: {
    NEXT_PUBLIC_GOOGLE_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_API_KEY,
    NEXT_PUBLIC_BASE_API_URL: process.env.NEXT_PUBLIC_BASE_API_URL,
    NEXT_PUBLIC_WEBSOCKET: process.env.NEXT_PUBLIC_WEBSOCKET,
    NEXT_PUBLIC_GOOGLE_EVENT_CLIENT:
      process.env.NEXT_PUBLIC_GOOGLE_EVENT_CLIENT,
    NEXT_PUBLIC_GOOGLE_EVENT_API: process.env.NEXT_PUBLIC_GOOGLE_EVENT_API,
  },
});
