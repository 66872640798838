import {useTheme as useStyledTheme} from 'styled-components';

import 'styled-components';

// Override styled components theme
declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends ThemeT {}
}

export const colors = {
  neutral000: 'rgba(255, 255, 255, 0.7)',
  neutral100: '#FFFFFF',
  neutral150: '#F5F4F6',
  neutral200: '#2F30710D',
  neutral300: '#2F307129',
  neutral500: '#1A181880',
  neutral600: '#8D8C8C',
  neutral800: '#1A1818',
  neutral850: '#494954',
  neutral900: '#000000',

  primary100: '#F4F5FF',
  primary150: '#DDDEE8',
  primary200: '#CECFF2',
  primary250: '#F1F1FC',
  primary300: '#E3D6F9',
  primary400: '#9798B8',
  primary450: 'rgba(47, 48, 113, 0.8)',
  primary500: '#2F3071',

  secondary100: '#F1EBFC',
  secondary200: '#E3D6F9',
  secondary400: '#9f74eb',

  warning500: '#FDAF01',
  warning600: '#F499AF',
  danger500: '#E10101',
  success500: '#00CD39',
  blue300: '#DDDEE8',
  blue400: 'rgba(151, 152, 184, 1)',
  violet600: '#9F74EB',
} as const;

export const lightTheme = {
  colors: colors,
  spacing: {
    xs: 4,
    s: 8,
    m: 16,
    l: 24,
    xl: 40,
    xxl: 50,
  },
  breakpoints: {
    phone: 0,
    tablet: 768,
  },
  borderRadii: {
    xs: 4,
    s: 8,
    m: 16,
    l: 24,
    xl: 40,
    xxl: 50,
  },
  shadow: {
    shadow1: '0px 4px 20px rgba(0, 0, 0, 0.063)',
    shadow2: '0px  4px 20px rgba(0, 0, 0, 0.33)',
  },
};

// Create dark theme
// export const darkTheme = createTheme({
//   ...lightTheme,
//   colors: {
//     ...lightTheme.colors,
//     mainBackground: palette.black,
//     cardPrimaryBackground: palette.black,
//   },
// });

export type ThemeT = typeof lightTheme;

export type ThemeVariantT = 'light' | 'dark';

export const useTheme = () => useStyledTheme();
