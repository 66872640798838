import {ChangeUserRoleReq, User} from '@src/models/user';
import {api} from './client';
import {Club} from '@src/models/club';
import {UserProfileI} from '@src/models/user';
import {Pagination} from '@src/models/pagination';

export namespace UserApi {
  export const getCurrentUser = async () => {
    const res = await api.get<User>('users/current');
    return res.data;
  };
  export const deleteCurrentUserAccount = async () => {
    const res = await api.delete('users');
    return res.data;
  };

  export interface UpdateCurrentUserBodyReq {
    name?: string;
    surname?: string;
    privatePhone?: string | null;
    mobilePhone?: string | null;
    workPhone?: string | null;
    birthDate?: string;
    userLanguages?: string[];
    address?: {
      address: string;
      city: string;
      zipCode: string;
      country: string;
    };
    titleId?: string | null;
    companyName?: string | null;
    email?: string;
    secondEmail?: string | null;
    secondName?: string | null;
    familyName?: string | null;
    salutationId?: string | null;
    oldPassword?: string;
    newPassword?: string;
    /**FOR WHEN ADMIN USES THIS ENDPOINT */
    userId?: string;
    receiptType?: string;
    gender?: string;
  }

  export const updateCurrentUser = async (body: UpdateCurrentUserBodyReq) => {
    const res = await api.put<unknown>('users/update', {
      userData: {
        name: body.name || undefined,
        surname: body.surname || undefined,
        privatePhone: body.privatePhone,
        workPhone: body.workPhone,
        birthDate: body.birthDate,
        userLanguages: body.userLanguages,
        receiptType: body.receiptType || undefined,
        address: body.address || undefined,
        titleId: body.titleId || undefined,
        companyName: body.companyName,
        email: body.email,
        secondEmail: body.secondEmail,
        secondName: body.secondName,
        // familyName: body.familyName,
        salutationId: body.salutationId || null,
        oldPassword: body.oldPassword || undefined,
        newPassword: body.newPassword || undefined,
        mobilePhone: body.mobilePhone,
        userId: body.userId || undefined,
        gender: body.gender || undefined,
      },
    });
    return res.data;
  };
  export const getCurrentUserClubs = async () => {
    const res = await api.get<Pagination<Club>>('companies/user-companies');
    return res.data.data;
  };

  export interface ReportUserReq {
    description: string;
  }
  export const reportUser = async (id: string, data: ReportUserReq) => {
    const res = await api.post(`users/${id}/report`, data);
    return res.data;
  };

  export const getUser = async (
    id: string,
    clubId: string
  ): Promise<UserProfileI> => {
    const res = await api.get<UserProfileI>(
      `users/user-details/${id}/${clubId}`
    );
    return res.data;
  };

  export const getUserByIdChat = async (id: string) => {
    const res = await api.get(`users/user-by-id/${id}`);
    return res.data;
  };
  export const getUserById = async (id: string, clubId?: string) => {
    const res = await api.get(
      `users/user-details/${id}${clubId ? '/' + clubId : ''}`
    );
    return res.data;
  };
  export interface UpdateUserLanguageBodyReq {
    language: string[];
  }
  export interface UpdateUserLanguagesBodyReq {
    language: string;
  }

  export const updateUserLanguage = async (
    body: UpdateUserLanguageBodyReq
  ): Promise<void> => {
    await api.put('/users/change-language', body);
  };
  export const changeUserLanguage = async (
    body: UpdateUserLanguagesBodyReq
  ): Promise<void> => {
    const res = await api.put('/users/languages', body);
    return res.data;
  };
  export const changeUserRoleInCompany = async (body: ChangeUserRoleReq) => {
    const res = await api.put('companies/change-role', body);
    return res.data;
  };
  export const updateCreateClub = async (body: {createClub: boolean}) => {
    const res = await api.put('users/createClub', body);
    return res.data;
  };
  export const changeImagesOrder = async (body) => {
    const res = await api.put('users/gallery-order ', body);
    return res.data;
  };
}
